import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from '../component/layout/Layout';
import { PermissionActions } from '../actions';

const Dashboard = React.lazy(() => import('../component/DashboardComponent/DashBoard'));
const BuyerPlatform = React.lazy(() => import('../component/BuyerPlatformComponent/BuyerPlatform'));
const AddBuyerPlatform = React.lazy(() => import('../component/BuyerPlatformComponent/AddBuyerPlatform/AddBuyerPlatform'));
const View_Edit_BuyerPlatform = React.lazy(() => import('../component/BuyerPlatformComponent/View_Edit_BuyerPlatform/View_Edit_BuyerPlatform'));
const Request = React.lazy(() => import('../component/RequestComponent/Request'));
const Merchants = React.lazy(() => import('../component/MerchantComponent/Merchant'));
const ViewMerchant = React.lazy(() => import('../component/MerchantComponent/ViewMerchant/ViewMerchant'));
const CompanyDetails = React.lazy(() => import('../pages/CompanyDetails/CompanyDetails'));
const BankDetails = React.lazy(() => import('../pages/BankDetails/BankDetails'));
const VirtualBankDetails = React.lazy(() => import('../pages/VirtualBankDetails/VirtualBankDetails'));
const CreateAccountScreen = React.lazy(() => import('../pages/CreateAccountScreen/CreateAccountScreen'));
const TermsScreen = React.lazy(() => import('../pages/TermsScreen/TermsScreen'));
const EditAccount = React.lazy(() => import('../pages/EditAccount/EditAccount'));
const Document = React.lazy(() => import('../pages/Document/Document'));
const BuyerPlatformSetup = React.lazy(() => import('../pages/BuyerPlatformSetup/BuyerPlatformSetup'));
const Payment = React.lazy(() => import('../component/PaymentComponent/Payment'));
const Invoices = React.lazy(() => import('../pages/Invoices/invoices'));
const AddEditInvoice = React.lazy(() => import('../pages/Invoices/addEditInvoice'));
const InvoicesView = React.lazy(() => import('../pages/Invoices/invoicesview'));
const Pending = React.lazy(() => import('../pages/Pending/pending'));
const AddEditPending = React.lazy(() => import('../pages/Pending/addEditPending'));
const PendingView = React.lazy(() => import('../pages/Pending/pendingview'));
const Wallet = React.lazy(() => import('../pages/Wallet/wallet'));
const EditWallet = React.lazy(() => import('../pages/Wallet/editWallet'));
const AddWallet = React.lazy(() => import('../pages/Wallet/addWallet'));
const WalletView = React.lazy(() => import('../pages/Wallet/walletview'));
const Traditional = React.lazy(() => import('../pages/FactParam/traditional'));
const EditTraditional = React.lazy(() => import('../pages/FactParam/editTraditional'));
const TraditionalView = React.lazy(() => import('../pages/FactParam/traditionalview'));
const Purchase = React.lazy(() => import('../pages/Purchase/purchase'));
const PoDetails = React.lazy(() => import('../pages/Purchase/po-details'));
const PurchaseView = React.lazy(() => import('../pages/Purchase/purchaseview'));
const AddEditPurchase = React.lazy(() => import('../pages/Purchase/addEditPurchase'));
const Requests = React.lazy(() => import('../pages/Requests/requests'));
const AddEditRequests = React.lazy(() => import('../pages/Requests/addEditRequests'));
const RequestsView = React.lazy(() => import('../pages/Requests/requestsview'));
const Tradepayments = React.lazy(() => import('../pages/TradePayments/tradepayments'));
const addEditTradePayments = React.lazy(() => import('../pages/TradePayments/addEditTradepayments'));
const TradePaymentsView = React.lazy(() => import('../pages/TradePayments/tradepaymentsview'));
const NotificationTemplate = React.lazy(() => import('../pages/Notifications/notificationtemplates'));
const UpdateNotificationTemplate = React.lazy(() => import('../pages/Notifications/updatenotificationtemplates'));
const NotificationView = React.lazy(() => import('../pages/Notifications/notificationView'));
const EmailTemplate = React.lazy(() => import('../pages/EmailTemplate/emailtemplates'));
const UpdateEmailTemplate = React.lazy(() => import('../pages/EmailTemplate/updateEmailtemplates'));
const TemplateView = React.lazy(() => import('../pages/EmailTemplate/templateView'));
const CmsPages = React.lazy(() => import('../pages/cmsPages/cmspages'));
const UpdateCmsPage = React.lazy(() => import('../pages/cmsPages/updateCmsPage'));
const CmsPageView = React.lazy(() => import('../pages/cmsPages/cmsPagesView'));
const AccountMonitoring = React.lazy(() => import('../pages/AccountMonitor/Monitoring'));
const DocumentMonitoring = React.lazy(() => import('../pages/DocumentMonitoring/DocumentMonitoring'));
const addEditDocumentLibrary = React.lazy(() => import('../pages/DocumentMonitoring/addEditDocumentLibrary'));
const addBankStatement = React.lazy(() => import('../pages/DocumentMonitoring/addBankStatement'));
const Investor = React.lazy(() => import('../pages/Investor/Investor'));
const Coupons = React.lazy(() => import('../pages/Coupons/CouponsList'));
const CouponParamList = React.lazy(() => import('../pages/CouponParams/CouponParamList'));
const addEditCouponParam = React.lazy(() => import('../pages/CouponParams/addEditCouponParam'));
const couponParamView = React.lazy(() => import('../pages/CouponParams/couponParamView'));
const Transactions = React.lazy(() => import('../pages/Transactions/TransactionsList'));
const addEditTransaction = React.lazy(() => import('../pages/Transactions/addEditTransaction'));
const transactionView = React.lazy(() => import('../pages/Transactions/transactionView'));
const addEditCoupon = React.lazy(() => import('../pages/Coupons/addEditCoupon'));
const couponView = React.lazy(() => import('../pages/Coupons/couponView'));
const InvestorProfile = React.lazy(() => import('../pages/Investor/InvestorProfile'));
const InvestorAddEdit = React.lazy(() => import('../pages/Investor/InvestorAddEdit'));
const Permission = React.lazy(() => import('../pages/Permission/Permission'));
const AddEditPermission = React.lazy(() => import('../pages/Permission/AddEditPermission'));
const UsersRole = React.lazy(() => import('../pages/Permission/UsersRole'));
const CreateUser = React.lazy(() => import('../pages/Permission/CreateUser'));
const EditUserRole = React.lazy(() => import('../pages/Permission/EditUserRole'));
const Remittances = React.lazy(() => import('../pages/Remittances/Remittances'));
const Configuration = React.lazy(() => import('../pages/Configurations/Configurations'));
const ConfigurationAddEdit = React.lazy(() => import('../pages/Configurations/AddEditConfiguration'));
const ConfigurationView = React.lazy(() => import('../pages/Configurations/ConfigurationView'));
const CronJobs = React.lazy(() => import('../pages/CronJobs'))
const RiskDeshboard = React.lazy(() => import('../pages/RiskDeshboard'))
const BankPayment = React.lazy(() => import('../pages/bankPayment/bankPayment'))
const EditBankPayment = React.lazy(() => import('../pages/bankPayment/editBankPayment'))
const partnersList = React.lazy(() => import('../pages/Partners/partnersList'));
const partnerAddEdit = React.lazy(() => import('../pages/Partners/partnerAddEdit'));
const partnerProfile = React.lazy(() => import('../pages/Partners/partnerProfile'));
const partnerFactParams = React.lazy(() => import('../pages/PartnerFactParams/partnerFactParams'));
const partnerFactParamsAddEdit = React.lazy(() => import('../pages/PartnerFactParams/partnerFactParamsAddEdit'));
const factParamView = React.lazy(() => import('../pages/PartnerFactParams/factParamView'));
const Commitment = React.lazy(() => import('../pages/Commitments/Commitments'));
const addEditCommitment = React.lazy(() => import('../pages/Commitments/addEditCommitments'));
const documentList = React.lazy(() => import('../pages/Documents/documentList'));
const addEditDocument = React.lazy(() => import('../pages/Documents/addEditDocument'));
const documentView = React.lazy(() => import('../pages/Documents/documentView'));
const PfpBankStatementList = React.lazy(() => import('../pages/PfpBankStatements/BankStatementList'));
const addEditBankStatement = React.lazy(() => import('../pages/PfpBankStatements/addEditBankStatement'));
const bankStatementView = React.lazy(() => import('../pages/PfpBankStatements/bankStatementView'));
const BankReciept = React.lazy(() => import('../pages/bankReciept/bankReciept'));
const AmazonInvoice = React.lazy(() => import('../pages/amazonInvoices/amazonInvoices'));
const viewCommitment = React.lazy(() => import('../pages/Commitments/commitmentView'));
const UnAuthorized = React.lazy(() => import('../pages/UnAuthorized'));
class Private extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUrlPath: window.location.pathname
        }
    }

    componentDidMount() {
        this.props.getUserRoleMenu(window.localStorage.getItem("_role"))
    }

    getUserPermission = (url) => {
        
        // let urlMatch = '/'
        // if(url != '/'){
        //     let splitUrl = url.split('/');
        //     // let urlMatch = splitUrl[0] + '/' + splitUrl[1] + '/' + splitUrl[2];
        //     urlMatch = splitUrl[0] + '/' + splitUrl[1];
        // }
        if (this.props.userRoleMenuList.length > 0) {
            // Add date for permissions
            // let roleMenuData = this.props.userRoleMenuList.find((item) => {
            //     if (item.menu.menuUrl && item.menu?.menuUrl.includes(url)) {
            //         return item.menu;
            //     }
            // });

            let roleMenuData = this.props.userRoleMenuList.filter((item) => {
                // return item.menu.menuUrl && item.menu?.menuUrl.includes(url)
                return item.menu.menuUrl && item.menu?.menuUrl === url
            })
            if (roleMenuData.length > 0) {
                localStorage.setItem("menuUrl", url);
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    render() {
        return (
            <div className="App" >
                <Layout />
                <div className="body-container" >
                    <React.Suspense fallback={<p>loading...</p>}>
                        <Switch>
                            {<Route exact path="/" component={this.getUserPermission("/") ? Dashboard : UnAuthorized} /> }
                            {<Route exact path="/admin/buyerplatforms" component={this.getUserPermission("/admin/buyerplatforms") ? BuyerPlatform : UnAuthorized} />}
                            {<Route exact path="/admin/addBuyerPlatform" component={this.getUserPermission("/admin/addBuyerPlatform") ? AddBuyerPlatform : UnAuthorized} />}
                            {<Route exact path="/admin/buyerplatforms/:id" component={this.getUserPermission("/admin/buyerplatforms/:id") ? View_Edit_BuyerPlatform : UnAuthorized} />}

                            {<Route exact path="/admin/merchants" component={this.getUserPermission("/admin/merchants") ?  Merchants : UnAuthorized} /> }
                            {<Route exact path="/admin/merchant/:id" component={this.getUserPermission("/admin/merchant/:id") ? ViewMerchant : UnAuthorized} /> }
                            {<Route exact path="/admin/merchant/companyDetail/:id" component={this.getUserPermission("/admin/merchant/companyDetail/:id") ? CompanyDetails : UnAuthorized} />}
                            {<Route exact path="/admin/merchant/bankDetail/:id" component={this.getUserPermission("/admin/merchant/bankDetail/:id") ? BankDetails : UnAuthorized} />}
                            {<Route exact path="/admin/merchant/virtualBankDetail/:id" component={this.getUserPermission("/admin/merchant/virtualBankDetail/:id") ? VirtualBankDetails : UnAuthorized} /> }
                            {<Route exact path="/admin/addMerchant" component={this.getUserPermission("/admin/addMerchant") ? CreateAccountScreen : UnAuthorized} /> }
                            {<Route exact path="/admin/merchant/account/:id" component={this.getUserPermission("/admin/merchant/account/:id") ? EditAccount : UnAuthorized} />}
                            {<Route exact path="/admin/addMerchant/tnc/:id/:name/:phone/:email/:pass" component={this.getUserPermission("/admin/addMerchant/tnc/:id/:name/:phone/:email/:pass") ? TermsScreen : UnAuthorized} />}
                            {<Route exact path="/admin/merchant/documents/:id" component={this.getUserPermission("/admin/merchant/documents/:id") ? Document : UnAuthorized} /> }
                            {<Route exact path="/admin/merchant/setUpBuyerPlatform/:userId/:selectedBP" component={this.getUserPermission("/admin/merchant/setUpBuyerPlatform/:userId/:selectedBP") ? BuyerPlatformSetup  : UnAuthorized} />}

                            {<Route exact path="/admin/payments" component={this.getUserPermission("/admin/payments") ? Payment : UnAuthorized} />}

                            {<Route exact path="/admin/invoices" component={this.getUserPermission("/admin/invoices") ? Invoices  : UnAuthorized} />}
                            {<Route exact path="/admin/invoices-view/:id" component={this.getUserPermission("/admin/invoices-view/:id") ? InvoicesView : UnAuthorized} /> }
                            {<Route exact path="/admin/add-invoice" component={this.getUserPermission("/admin/add-invoice") ? AddEditInvoice : UnAuthorized} /> }
                            {<Route exact path="/admin/edit-invoice/:id" component={this.getUserPermission("/admin/edit-invoice/:id") ? AddEditInvoice : UnAuthorized} />}

                            {<Route exact path="/admin/pending" component={this.getUserPermission("/admin/pending") ? Pending : UnAuthorized} />}
                            {<Route exact path="/admin/pending-view/:id" component={this.getUserPermission("/admin/pending-view/:id") ? PendingView : UnAuthorized} />}
                            {<Route exact path="/admin/add-pending" component={this.getUserPermission("/admin/add-pending") ? AddEditPending : UnAuthorized} />}
                            {<Route exact path="/admin/edit-pending/:id" component={this.getUserPermission("/admin/edit-pending/:id") ? AddEditPending : UnAuthorized} />}

                            <Route exact path="/admin/wallet" component={this.getUserPermission("/admin/wallet") ? Wallet : UnAuthorized } />
                            {<Route exact path="/admin/wallet-view/:id" component={this.getUserPermission("/admin/wallet-view/:id") ? WalletView : UnAuthorized} />}
                            {<Route exact path="/admin/add-wallet" component={this.getUserPermission("/admin/add-wallet") ? AddWallet: UnAuthorized} /> }
                            {<Route exact path="/admin/edit-wallet/:id" component={this.getUserPermission("/admin/edit-wallet/:id") ? EditWallet : UnAuthorized} />}

                            {<Route exact path="/admin/traditional" component={this.getUserPermission("/admin/traditional") ? Traditional : UnAuthorized} />}
                            {<Route exact path="/admin/add-traditional" component={this.getUserPermission("/admin/add-traditional") ? EditTraditional : UnAuthorized} />}
                            {<Route exact path="/admin/edit-traditional/:id" component={this.getUserPermission("/admin/edit-traditional/:id") ? EditTraditional  : UnAuthorized} />}
                            {<Route exact path="/admin/traditional-view/:id" component={this.getUserPermission("/admin/traditional-view/:id") ? TraditionalView : UnAuthorized} /> }
                            {<Route exact path="/a/admin/requesdmin/po-details" component={this.getUserPermission("/a/admin/requesdmin/po-details") ? PoDetails  : UnAuthorized} />}
                            {<Route exact path="/admin/purchase" component={this.getUserPermission("/admin/purchase") ? Purchase  : UnAuthorized} />}
                            {<Route exact path="/admin/edit-purchase/:id" component={this.getUserPermission("/admin/edit-purchase/:id") ? AddEditPurchase : UnAuthorized} />}
                            {<Route exact path="/admin/add-purchase" component={this.getUserPermission("/admin/add-purchase") ? AddEditPurchase : UnAuthorized} /> }
                            {<Route exact path="/admin/purchase-view/:id" component={this.getUserPermission("/admin/purchase-view/:id") ? PurchaseView : UnAuthorized} />}

                            {<Route exact path="/admin/add-requests" component={this.getUserPermission("/admin/add-requests") ? AddEditRequests  : UnAuthorized} />}
                            {<Route exact path="/admin/edit-requests/:id" component={this.getUserPermission("/admin/edit-requests/:id") ? AddEditRequests : UnAuthorized} />}
                            {<Route exact path="/admin/requests-view/:id" component={this.getUserPermission("/admin/requests-view/:id") ? RequestsView  : UnAuthorized} />}
                            {<Route exact path="/admin/trade-payments" component={this.getUserPermission("/admin/trade-payments") ? Tradepayments : UnAuthorized} />}
                            {<Route exact path="/admin/add-tradepayments" component={this.getUserPermission("/admin/add-tradepayments") ? addEditTradePayments : UnAuthorized} />}
                            {<Route exact path="/admin/edit-tradepayments/:id" component={this.getUserPermission("/admin/edit-tradepayments/:id") ? addEditTradePayments : UnAuthorized} />}
                            {<Route exact path="/admin/tradepayments-view/:id" component={this.getUserPermission("/admin/tradepayments-view/:id") ? TradePaymentsView  : UnAuthorized} />}
                            {<Route exact path="/admin/notification-template" component={this.getUserPermission("/admin/notification-template") ? NotificationTemplate  : UnAuthorized} />}
                            {<Route exact path="/admin/edit-notification-template/:id" component={this.getUserPermission("/admin/edit-notification-template/:id") ? UpdateNotificationTemplate : UnAuthorized} />}
                            {<Route exact path="/admin/view-notification-template/:id" component={this.getUserPermission("/admin/view-notification-template/:id") ? NotificationView  : UnAuthorized} />}
                            {<Route exact path="/admin/email-template" component={this.getUserPermission("/admin/email-template") ? EmailTemplate : UnAuthorized} />}
                            {<Route exact path="/admin/edit-email-template/:id" component={this.getUserPermission("/admin/edit-email-template/:id") ? UpdateEmailTemplate  : UnAuthorized} />}
                            {<Route exact path="/admin/view-email-template/:id" component={this.getUserPermission("/admin/view-email-template/:id") ? TemplateView : UnAuthorized} />}
                            {<Route exact path="/admin/cms-page" component={this.getUserPermission("/admin/cms-page") ? CmsPages : UnAuthorized} />}
                            {<Route exact path="/admin/edit-cms-page/:id" component={this.getUserPermission("/admin/edit-cms-page/:id") ? UpdateCmsPage : UnAuthorized} />}
                            {<Route exact path="/admin/view-cms-page/:id" component={this.getUserPermission("/admin/view-cms-page/:id") ? CmsPageView : UnAuthorized} />}

                            {<Route exact path="/admin/client-monitoring" component={this.getUserPermission("/admin/client-monitoring") ? AccountMonitoring : UnAuthorized} /> }
                            {<Route exact path="/admin/document-monitoring" component={this.getUserPermission("/admin/document-monitoring") ? DocumentMonitoring : UnAuthorized} />}
                            {<Route exact path="/admin/edit-document-monitoring/:id" component={this.getUserPermission("/admin/edit-document-monitoring/:id") ? addEditDocumentLibrary : UnAuthorized} />}
                            {<Route exact path="/admin/add-document-monitoring" component={this.getUserPermission("/admin/add-document-monitoring/") ? addEditDocumentLibrary : UnAuthorized} />}
                            {<Route exact path="/admin/add-bank-statement" component={this.getUserPermission("/admin/add-document-monitoring/") ? addBankStatement : UnAuthorized} />}
                            {<Route exact path="/admin/investor" component={this.getUserPermission("/admin/investor") ? Investor : UnAuthorized} />}
                            {<Route path="/admin/investor/view/:id" component={this.getUserPermission("/admin/investor/view/:id") ? InvestorProfile : UnAuthorized} />}
                            {<Route path="/admin/investor/add" component={this.getUserPermission("/admin/investor/add") ? InvestorAddEdit  : UnAuthorized} />}
                            {<Route path="/admin/investor/edit/:id" component={this.getUserPermission("/admin/investor/edit/:id") ? InvestorAddEdit : UnAuthorized} />}

                            {<Route path="/admin/coupons" component={this.getUserPermission("/admin/coupons") ? Coupons : UnAuthorized} />}
                            {<Route path="/admin/coupon/add" component={this.getUserPermission("/admin/coupon/add") ? addEditCoupon : UnAuthorized} />}
                            {<Route path="/admin/coupon/edit/:id" component={this.getUserPermission("/admin/coupon/edit/:id") ? addEditCoupon : UnAuthorized} />}
                            {<Route path="/admin/coupon/view/:id" component={this.getUserPermission("/admin/coupon/view/:id") ? couponView : UnAuthorized} /> }
                            {<Route path="/admin/coupon-params" component={this.getUserPermission("/admin/coupon-params") ? CouponParamList : UnAuthorized} />}
                            {<Route path="/admin/coupon-param/add" component={this.getUserPermission("/admin/coupon-param/add") ? addEditCouponParam : UnAuthorized} />}
                            {<Route path="/admin/coupon-param/edit/:id" component={this.getUserPermission("/admin/coupon-param/edit/:id") ? addEditCouponParam  : UnAuthorized} />}
                            {<Route path="/admin/coupon-param/view/:id" component={this.getUserPermission("/admin/coupon-param/view/:id") ? couponParamView : UnAuthorized} />}
                            {<Route path="/admin/transactions" component={this.getUserPermission("/admin/transactions") ? Transactions : UnAuthorized} />}
                            {<Route path="/admin/transaction/add" component={this.getUserPermission("/admin/transaction/add") ? addEditTransaction : UnAuthorized} />}
                            {<Route path="/admin/transaction/edit/:id" component={this.getUserPermission("/admin/transaction/edit/:id") ? addEditTransaction : UnAuthorized} />}
                            {<Route path="/admin/transaction/view/:id" component={this.getUserPermission("/admin/transaction/view/:id") ? transactionView: UnAuthorized} /> }

                        
                            {<Route exact path="/admin/permission/users-role/:id" component={this.getUserPermission("/admin/permission/users-role/:id") ? UsersRole : UnAuthorized} />}
                            {<Route exact path="/admin/permission/:id" component={this.getUserPermission("/admin/permission/:id") ? AddEditPermission: UnAuthorized} /> }
                            {<Route exact path="/admin/permission" component={this.getUserPermission("/admin/permission") ? Permission : UnAuthorized} />}
                            {<Route exact path="/admin/create/user" component={this.getUserPermission("/admin/create/user") ? CreateUser : UnAuthorized} />}
                            {<Route exact path="/admin/edit/user/role/:userId/:roleId" component={this.getUserPermission("/admin/edit/user/role/:userId/:roleId") ? EditUserRole : UnAuthorized} />}
                            {<Route exact path="/admin/requests" component={this.getUserPermission("/admin/requests") ?  Requests : UnAuthorized} /> }
                            {<Route exact path="/admin/bankPayment" component={this.getUserPermission("/admin/bankPayment") ? BankPayment : UnAuthorized} />}
                            {<Route exact path="/admin/edit-bankPayment/:id" component={this.getUserPermission("/admin/edit-bankPayment/:id") ? EditBankPayment : UnAuthorized} />}
                            {<Route path="/admin/partners" component={this.getUserPermission("/admin/partners") ? partnersList : UnAuthorized} />}
                            {<Route path="/admin/partner/add" component={this.getUserPermission("/admin/partner/add") ? partnerAddEdit : UnAuthorized} />}
                            {<Route path="/admin/partner/edit/:id" component={this.getUserPermission("/admin/partner/edit/:id") ? partnerAddEdit : UnAuthorized} />}
                            {<Route path="/admin/partner/view/:id" component={this.getUserPermission("/admin/partner/view/:id") ? partnerProfile : UnAuthorized} />}
                            {<Route path="/admin/partner-fact-params" component={this.getUserPermission("/admin/partner-fact-params") ? partnerFactParams : UnAuthorized} />}
                            {<Route path="/admin/fact-params/add" component={this.getUserPermission("/admin/fact-params/add") ? partnerFactParamsAddEdit: UnAuthorized} /> }
                            {<Route path="/admin/fact-params/edit/:id" component={this.getUserPermission("/admin/fact-params/edit/:id") ? partnerFactParamsAddEdit: UnAuthorized} /> }
                            {<Route path="/admin/fact-params/view/:id" component={this.getUserPermission("/admin/fact-params/view/:id") ? factParamView : UnAuthorized} />}
                            {<Route path="/admin/partner/bankDetails/:partnerId" component={this.getUserPermission("/admin/fact-params/bankDetails/:partnerId") ? BankDetails: UnAuthorized} /> }
                            {<Route path="/admin/commitment" component={this.getUserPermission("/admin/commitment") ? Commitment : UnAuthorized} />}
                            {<Route path="/admin/commitments/add" component={this.getUserPermission("/admin/commitments/add") ? addEditCommitment: UnAuthorized} /> }
                            {<Route path="/admin/commitments/view/:id" component={this.getUserPermission("/admin/commitments/view/:id") ? viewCommitment: UnAuthorized} /> }

                            {<Route path="/admin/commitments/edit/:id" component={this.getUserPermission("/admin/commitments/edit/:id") ? addEditCommitment : UnAuthorized} />}
                            
                            {<Route path="/admin/documents" component={this.getUserPermission("/admin/documents") ?documentList : UnAuthorized} />}
                            {<Route path="/admin/document/add" component={this.getUserPermission("/admin/document/add") ? addEditDocument : UnAuthorized} />}
                            {<Route path="/admin/document/edit/:id" component={this.getUserPermission("/admin/document/edit/:id") ?addEditDocument : UnAuthorized} />}
                            {<Route path="/admin/document/view/:id" component={this.getUserPermission("/admin/document/view/:id") ?documentView : UnAuthorized} />}

                            {<Route path="/admin/pfpbank/statements" component={this.getUserPermission("/admin/pfpbank/statements") ? PfpBankStatementList : UnAuthorized} />}

                            {<Route path="/admin/pfpbank/statement/add" component={this.getUserPermission("/admin/pfpbank/statement/add") ? addEditBankStatement : UnAuthorized} />
                                }

                            {<Route path="/admin/pfpbank/statement/edit/:id" component={this.getUserPermission("/admin/pfpbank/statement/edit/:id") ?addEditBankStatement: UnAuthorized} />
                                }

                            {<Route path="/admin/pfpbank/statement/view/:id" component={this.getUserPermission("/admin/pfpbank/statement/view/:id") ? bankStatementView : UnAuthorized} />}

                            {<Route exact path="/admin/bankReciept" component={this.getUserPermission("/admin/bankReciept") ? BankReciept : UnAuthorized} />}


                            {<Route exact path="/admin/amazonInvoices" component={this.getUserPermission("/admin/amazonInvoices") ? AmazonInvoice : UnAuthorized} />}

                            {<Route exact path="/admin/cronjobs" component={this.getUserPermission("/admin/cronjobs") ? CronJobs : UnAuthorized} />}
                            {<Route exact path="/admin/riskdashboard" component={this.getUserPermission("/admin/riskdashboard") ? RiskDeshboard : UnAuthorized} />}
                            {<Route exact path="/admin/remittances" component={this.getUserPermission("/admin/remittances") ? Remittances : UnAuthorized} />}
                            {<Route exact path="/admin/configuration" component={this.getUserPermission("/admin/configuration") ? Configuration : UnAuthorized} />}
                            {<Route exact path="/admin/configuration" component={this.getUserPermission("/admin/configuration") ? Configuration : UnAuthorized} />}

                            {<Route exact path="/admin/configuration/add" component={this.getUserPermission("/admin/configuration/add") ? ConfigurationAddEdit : UnAuthorized} />}
                            {<Route exact path="/admin/configuration/edit/:id" component={this.getUserPermission("/admin/configuration/edit/:id") ? ConfigurationAddEdit: UnAuthorized} /> }
                            {<Route exact path="/admin/configuration/view/:id" component={this.getUserPermission("/admin/configuration/view/:id") ? ConfigurationView : UnAuthorized} />}


                            <Route exact path="/admin/page/UnAuthorized" component={UnAuthorized} />

                        </Switch>
                    </React.Suspense>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ PermissionReducers }) => {

    return {
        userRoleMenuList: PermissionReducers.userRoleMenuList,
    };
}

const mapDispatchToProps = {
    getUserRoleMenu: PermissionActions.getUserRoleMenu,
}
export default connect(mapStateToProps, mapDispatchToProps)(Private)