import React, { useState } from 'react';
import './Header.css';
import Notifications from '../../Model/Notifications'
import { checkTokenExpired } from '../../../services/utilService';
import { Link,useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
//import {FunderLogo} from '../assests/FunderLogo.jpg'; 
import { Api } from '../../../services/httpService';
import Swal from "sweetalert2";
import { useIdleTimer } from 'react-idle-timer';
import { isAllowSessionTime } from '../../../baseURL';

const Header = () => {
    const history = useHistory()
    let timeLeft = 59;
    checkTokenExpired();
    const logo = require('../../../assests/Funder.ai.png');
    const bell = require('../../../assests/bell.png');
    const circleWithName = require('../../../assests/Eclipse.png');

    const [notificationsModal, setNotificationsModal] = useState(false)
    const closeModal = (data) => {
        setNotificationsModal(data)
    }

    const logout = async () => {
        Swal.fire({
            title: 'Are you sure!',
            text: 'Do you want to logout?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            confirmButtonColor: "var(--bg-theme)",
        }).then(result => {
            if (result.isConfirmed) {
                const res = Api('post', '/users/logout');
                if (res) {
                    window.localStorage.clear();
                    window.location = '/';
                }
            }
        });
    }

    /* for idel use */
    const [isIdle, setIsIdle] = useState(false);


    const updateTimerHtml = () => {
        return `<div style="color: #009e49; background: #fff; padding: 10px; border-radius: 5px;">
                    You will be logged out in <b>${timeLeft}</b> seconds.
                </div>`;
    };

    const handleOnIdle = () => {
        setIsIdle(true);
        // Show the Swal alert and store the promise
        Swal.fire({
            title: 'You have been inactive',
            html: updateTimerHtml(),
            icon: 'warning',
            timer: 60 * 1000,
            timerProgressBar: true,
            showCancelButton: false,
            confirmButtonText: 'Stay Logged In',
            allowOutsideClick: true,
            didOpen: () => {
                // Start the timer interval when the alert opens
                const timerInterval = setInterval(() => {
                    if (timeLeft > 0) {
                        timeLeft -= 1;
                        // Update the HTML of the specific Swal instance
                        const htmlContainer = Swal.getHtmlContainer();
                        if (htmlContainer) {
                            htmlContainer.innerHTML = updateTimerHtml();
                        }
                    } else {
                        clearInterval(timerInterval);
                        // Swal.close(); // Close the Swal when the timer ends
                        // Perform any additional actions (e.g., logout)
                        // Api('post', '/users/logout').then(() => {
                        //     console.log('setValue-1')
                        //     window.localStorage.clear();
                        //     window.localStorage.setItem('isTimeSessionOutAD', true);
                        //     window.location = '/';
                        // });
                    }
                }, 1000);
            }
        }).then((result) => {
            if (result.isConfirmed) {
                // Reset the idle state and refresh the session
                setIsIdle(false);
                console.log('result.isConfirmed')
            } else if (result.isDismissed && result.dismiss === Swal.DismissReason.timer) {
                const res = Api('post', '/users/logout');
                if (res) {
                    Swal.close();
                    window.localStorage.clear();
                    setTimeout(()=>{
                        history.push('/')
                    },1000)
                }
                setIsIdle(false);
            } else if (result.isDismissed && result.dismiss === Swal.DismissReason.backdrop) {
                setIsIdle(false);
                // window.location.reload();
                console.log('result.isDismissed')
            }
        });
    };

    const handleOnActive = () => {
        setIsIdle(false);
    };

    useIdleTimer({
        timeout: isAllowSessionTime, // 1 minute
        //timeout: 1 * 60 * 1000, // 1 minute
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        debounce: 500,
    });

    return (
        <div>
            <div id="header">
                <div className="row align-items-center">
                    <div className="col-6 pl-0">
                        <div className="top_left_title"><img src={logo} /></div>
                    </div>
                    <div className="col-6 text-right">
                        <div className="bell cursor">
                            <img src={bell} width={17} height={17} onClick={() => {
                                setNotificationsModal(true)
                            }} />
                        </div>

                        <Link className="ml-4 headerLogOut" to="#" onClick={() => logout()}>
                            <FontAwesomeIcon color='var(--color-f2)' icon={faSignOutAlt} />
                        </Link>

                    </div>
                </div>
            </div>
            {notificationsModal && (
                <Notifications
                    openModal={notificationsModal}
                    closeModal={closeModal}
                    data={[]}
                />
            )}
        </div>
    );
}

export default Header;