import t from "./types";
import { Api } from '../../services/httpService';



export const getMenuList = () => async (dispatch) => {
    let res = await Api('GET', `/menu/findAll`);
    if (res.success) {
        dispatch({ type: t.GET_MENU_LIST, payload: res })
    }
}

export const addMenuAccess = (param) => async (dispatch) => {
    let res = await Api('POST', `/menu/menuAccess/entry`, param);
}

export const getRoleList = () => async (dispatch) => {
    try {
        let res = await Api('GET', '/role/findAll');
        if (res.success) {
            dispatch({ type: t.GET_ROLE_LIST, payload: res })
        }
        else{
            if(res.status == 401){
                window.location = '/admin/page/UnAuthorized';
            }
            else{
                console.log("error----", res);
            }
        }
    } catch (error) {
        console.log("error", error);
    }
}

export const addEditRole = (param) => async (dispatch) => {
    try {
        let res = await Api("POST", '/role/entry', param);
        if (res.success) {
            dispatch({ type: t.ADD_EDIT_ROLE, payload: res })
        }

    } catch (error) {
        console.log("error", error);
    }
}

export const getRoleDetails = (roleId) => async (dispatch) => {
    try {
        let res = await Api("GET", `/role/findById/${roleId}`);
        if (res.success) {
            dispatch({ type: t.GET_ROLE_DETAILS, payload: res })
        }
    } catch (error) {
        console.log("error", error);
    }
}

export const addEditMenuAccess = (param) => async (dispatch) => {
    try {
        let res = await Api("POST", '/menu/menuAccess/entry', param);
    } catch (error) {
        console.log("error", error);
    }
}

export const getRoleMenu = (roleId) => async (dispatch) => {
    try {
        let res = await Api("GET", `/menu/getRoleMenu/${roleId}`);
        if (res.success) {
            dispatch({ type: t.GET_MENU_ROLE, payload: res });
        }
    } catch (error) {
        console.log("error", error);
    }
}
export const getUserRoleMenu = (roleId) => async (dispatch) => {
    try {
        let res = await Api("GET", `/menu/getRoleMenu/${roleId}`);
        if (res.success) {
            dispatch({ type: t.GET_USER_MENU_ROLE, payload: res });
        }
    } catch (error) {
        console.log("error", error);
    }
}

export const createUser = (param) => async (dispatch) => {
    try {
        let res = await Api('POST', '/merchant/entry', param);
        dispatch({ type: t.CREATE_USER, payload: res });
    } catch (error) {
        console.log("error", error);
    }
}


export const getUsersByRole = (roleId) => async (dispatch) => {
    try {
        let res = await Api('GET', `/role/getUsersByRole/${roleId}`);
        dispatch({ type: t.GET_USER_BY_ROLE, payload: res });
    } catch (error) {
        console.log("error", error);
    }
}


export const getUserList = () => async (dispatch) => {
    try {
        let res = await Api('GET', `/users/getAll`);
        dispatch({ type: t.GET_ALL_USERS, payload: res });
    } catch (error) {
        console.log("error", error);
    }
}






